import './slider.component.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';


function Slider() {
  let imgArray = [
    "slider_1.jpg"
  ];
 
  return (
      <Carousel >
        {imgArray.map((img,i)=>(
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={require('../../assets/img/slider/' + img+'')}
              alt="slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    
  );
}

export default Slider;