import './footer.component.scss';

function Footer() {
  return (
    <div className="footer">
      <div className="footer__info">
        <p className="footer__info-text">
          Un programa per al foment de la lectura, per Paola Sanz.
        </p>
        <a href="https://www.instagram.com/escoltaliteraria" className='footer__link'>
        <img className='footer__icon' src={require("../../assets/img/instagram_icono.png")} alt={"Instagram de Escolta Literaria"} width="30%" />
        </a>
        {/* <a href="" className='footer__link'>
        <img className='footer__icon' src={require("../../assets/img/tiktok_icono.png")} alt={"Tik Tok de Escolta Literaria"} width="30%" />
        </a>
        <a href="" className='footer__link'>
        <img className='footer__icon' src={require("../../assets/img/youtube_icono.png")} alt={"Youtube de Escolta Literaria"} width="30%" />
        </a> */}
      </div>
      <div className='footer__nav'>
        <h5 className='footer__info-text'>Enllaços ràpids:</h5>
        <div className='footer__nav-list'>
          <a href=" " className='footer__link'>Inici</a>
          <a href=" " className='footer__link'>Valors</a>
          <a href=" " className='footer__link'>Serveis</a>
          <a href=" " className='footer__link'>Contacta</a>
        </div>

      </div>
    </div>
  );
}

export default Footer;
