import './container.component.scss';
import React, { useState,useEffect } from "react";
import Header from '../header/header.component';
import Footer from '../footer/footer.component';
import Content from '../content/content.component';


function Container() {

  return (
    <div>
      {
        <div className="app-container">
          <Header></Header>
          <Content></Content>
          <Footer></Footer>
        </div>
      }
    </div>
  );

}

export default Container;
