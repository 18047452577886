import './header.component.scss';

function Header() {
  return (
    <>
      <div className='topbar'>
        <a href="https://www.instagram.com/escoltaliteraria">
        <img className='topbar__icon' src={require("../../assets/img/instagram_icono.png")} alt={"Instagram de Escolta Literaria"} />
        </a>
        {/* <a href="">
        <img className='topbar__icon' src={require("../../assets/img/tiktok_icono.png")} alt={"Tik Tok de Escolta Literaria"} />
        </a>
        <a href="">
        <img className='topbar__icon' src={require("../../assets/img/youtube_icono.png")} alt={"Youtube de Escolta Literaria"} />
        </a> */}
      </div>
      <div className="header">
        <a class="logo" href="https://www.escoltaliteraria.com/">
          <img className="header__img" src={require("../../assets/img/logotipo.png")}  alt={"Paola&Albert"}/>
        </a>
        <nav class="navbar">
          <ul class="navbar__list">
            <li class="navbar__list-item">
              <a class="navbar__list-item-link" href="#qui-som">Inici</a>
            </li>
            <li class="navbar__list-item">
              <a class="navbar__list-item-link" href="#qui-som">Valors</a>
            </li>
            <li class="navbar__list-item">
              <a class="navbar__list-item-link" href="#serveis">Serveis</a>
            </li>
            <li class="navbar__list-item">
              <a class="navbar__list-item-link" href="#contacta">Contacte</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Header;
