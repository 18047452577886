import React, { useRef, useState  } from "react";
import emailjs from "@emailjs/browser";
import './form.component.scss';

//alergias: 
// public key: uRbNPQHBWGZz3wYgB
// service id: service_q9ov6yl
//template id: template_mylo17r

const FormContacto = (props) => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
      setMessageSent(true);
       emailjs
         .sendForm(
           "service_q9ov6yl",
           "template_mylo17r",
           form.current,
           "uRbNPQHBWGZz3wYgB"
        )
        .then(
          (result) => {
            console.log("sent");

          },
         (error) => {

            console.log(error.text);
           }
       );
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="form">
          <div className="form__input-wrapper">
            <label className="form__label">
              Nom
            </label>
            <input  className="form__input" type="text" name="nom_text" id="nom_text"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Telèfon
            </label>
            <input  className="form__input" type="text" name="telefon_text" id="telefon_text"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Email
            </label>
            <input  className="form__input" type="text" name="email_text" id="email_text"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Tipus de servei a contractar
            </label>
            <input  className="form__input" type="text" name="servei_text" id="servei_text"/>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label">
              Missatge
            </label>
            <textarea className="form__input form__input--textarea" name="missatge_text" rows="4"></textarea>
          </div>

          <div className="form__input-wrapper">
            <input  className="form__input-checkbox" type="checkbox" name="privacy_checkbox" id="privacy_checkbox"/>
            <label className="form__label">
              Accepto que es possin en contacte amb mi.
            </label>
          </div>

          {!messageSent ?
            <input type="submit" value="Enviar" className="boton"/>
            : <p className={'form__message-sent form__message-sent--' + messageSent}>
                Moltes gràcies per el teu missatge!
              </p>
          }
        </form>
    );
  };
  
  export default FormContacto;