
import './content.component.scss';
import FormContacto from '../forms/form-contacto.component';
import Slider from '../slider/slider.component';
import { Fade } from "react-awesome-reveal";


function Content(props) {

  return (
    <div>
      <div className="principal">
        <Fade>
          <Slider></Slider>
        </Fade>
      </div>

      <div className="valors" id='valors'>
        <Fade>
          <h2 className="valors__title">Ética Corporativa</h2>
          <div className='valors__content'>
            <div className='valors__subsection'>
              <h3 className='valors__subsection-title'>
                Què ens defineix?
              </h3>
              <ul className='valors__subsection-list'>
                <li>
                  Equip amb formació específica sobre literatura infantil i juvenil i dinamització lectora. 
                </li>
                <li>
                  Foment d’una comunicació activa i asertiva. 
                </li>
                <li>
                  Innovació i creativitat en la creació de recursos per fomentar el gust per la lectura. 
                </li>
                <li>
                  Respecte per a  la diversitat cultural. 
                </li>
                <li>
                  Sensibilitat per la pluralitat de creences religioses.
                </li>
              </ul>
            </div>
            <div className='valors__subsection'>
              <h3 className='valors__subsection-title'>
                Quins objectius volem aconseguir?
              </h3>
              <ul className='valors__subsection-list'>
                <li>
                  Fomentar descoberta de noves propostes literàries. 
                </li>
                <li>
                  Estimular l’hàbit lector en tots els cicles de la vida. 
                </li>
                <li>
                  Desenvolupar una comunitat lectora cohesionada. 
                </li>
                <li>
                  Fomentar el diàleg social. 
                </li>
                <li>
                  Potenciar les capacitats i habilitats comunicatives dels lectors.
                </li>
              </ul>
            </div>
          </div>
        </Fade>
      </div>

        <Fade >
      <div className="quote">
          <div className="quote__image-wrapper">
            <img className='quote__image' src={require("../../assets/img/logotipo.png")} alt={"Escolta Literària"} width="100%" /> 
          </div>
          <div className="quote__text-wrapper">
            <p className="quote__text">
              Si vols aventura, llança't a la lectura.
            </p>
            <p className="quote__author">
              &mdash; escolta
              <span>literària</span>
            </p>
          </div>
          <div className="quote__image-wrapper">
            <img className='quote__icon' src={require("../../assets/img/comillas_icono.png")} alt={"Comillas"} width="80%" /> 
          </div>
      </div>
        </Fade>


      <div className="serveis" id='serveis'>
        <Fade>
        <h2 className="serveis__title">
          Serveis
        </h2>
        <div className="serveis__list">
            <div className="serveis__list-item">
            <img className='quote__icon' src={require("../../assets/img/mediacion-lectora_icono.png")} alt={"Mediació lectora"} width="30%" />
              <h4 className="serveis__list-item-title">
                Mediació Lectora
              </h4>
              <p className="serveis__list-item-text">
                Creació, organització i conducció de clubs de lectura per a infants, joves i adults. 
              </p>
            </div>
            <div className="serveis__list-item">
              <img className='quote__icon' src={require("../../assets/img/prescripcion-lectora_icono.png")} alt={"Prescripció Lectora"} width="30%" />
              <h4 className="serveis__list-item-title">
                Prescripció Lectora
              </h4>
              <p className="serveis__list-item-text">
              Elaborar i compartir continguts digitals sobre obres literàries i novetats editorials que tinguin una gran qualitat argumental i visual.
              </p>
            </div>

        </div>
        </Fade>
      </div>
      
      <Fade >
        <div className="contacto" id='contacte'>
          <h3 className="contacto__title">
            Contacta amb nosaltres
          </h3>
          <div className='contacto__form'>
            <FormContacto></FormContacto>
          </div>
        </div>
      </Fade>
      
    </div>
  );
};

export default Content;
